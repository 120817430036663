import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import LoaderContext from '../context/LoaderContext';
import TuesdayWine from '../util/TuesdayWine';
import jwtDecode from 'jwt-decode';
import { epochToLocalDateTime } from '../util/Util';
import { useHistory } from 'react-router-dom';

export default function SingleAnalyze({match}) {
    let {id} = match.params;
    
    const history = useHistory();

    const {setLoader, loggedIn} = useContext(LoaderContext);

    const [analysis, setAnalysis] = useState([])
    const [parameters, setParameters] = useState([])

    const [data, setData] = useState({});

    async function load() {
        const analysis = (await TuesdayWine.get(`/analyze/${id}`)).analysis;
        
        setData({
            step_tuesdaywine: {
                volume: analysis.step_tuesdaywine?.volume ?? analysis.tw_delivered ?? undefined
            },
        });
        
        setAnalysis(analysis)
        setParameters((await TuesdayWine.get(`/analyze/parameters`)).parameters)
        setLoader(false)
    }

    function handleSave(key) {
        return async () => {
            if (!data[key]) return;

            // Validate that required fields are filled in.
            for (let m of parameters) {
                if (m.required && !data[key][m.key]) {
                    return alert(`The parameter '${m.label}' is required`);
                }

                // Validate that there's only digits for each input
                if (m.type !== 'text' && data[key][m.key] !== undefined && data[key][m.key] !== null && !data[key][m.key].toString().match(/^[0-9.,-]+$/)) {
                    return alert(`The parameter '${m.label}' must only contain digits`);
                }
            }

            const resp = await TuesdayWine.put(`/analyze/${id}/${key.replace('step_', '')}`, data[key])
            console.log('resp', resp)
            load();
        }
    }

    function handleDelete(key) {
        return async () => {

            const confirm = window.confirm('Are you sure you want to reset this step?');
            if (!confirm) return;


            const resp = await TuesdayWine.delete(`/analyze/${id}/${key.replace('step_', '')}`)
            setData(d => ({
                ...d,
                [key]: {}
            }))
            console.log('resp', resp)
            load();
        }
    }

    function handleOnPaste(e) {
        const text = e.clipboardData.getData('text/plain');

        // Extracts the rows and columns from the text.
        const rows = text.split('\n').filter(m => m !== '' && m !== "\r" && m !== "\n").map(m => m.split('\t').filter(m => m !== '' && m !== "\r" && m !== "\n").map(v => {
            let val = v.trim();
            if (val[0] === "'") val = val.substring(1);
            return val;
        }));
        
        // Validate that the structure is correct.
        if (rows[0] && rows[0][0] === 'ID' && rows[0][1] === 'Product' && rows[1] && rows[1][0] !== undefined) {
            e.preventDefault();

            setData(d => ({
                ...d,
                step_tuesdaywine: {
                    ...d.step_tuesdaywine,
                    id: rows[1][1 - 1],
                    f_so2: rows[1][3 - 1],
                    t_so2: rows[1][4 - 1],
                    ethanol: rows[1][6 - 1],
                    density: rows[1][5 - 1],
                    glucose: rows[1][7 - 1],
                    fructose: rows[1][8 - 1],
                    glucose_fructose: rows[1][9 - 1],
                    reducing_sugar: rows[1][10 - 1],
                    acidity_8_2: rows[1][11 - 1],
                    acidity_7_0: rows[1][12 - 1],
                    ph: rows[1][11 - 1],
                    tartaric_acid: rows[1][14 - 1],
                    malic_acid: rows[1][13 - 1],
                    lactic_acid: rows[1][14 - 1],
                    citric_acid: rows[1][15 - 1],
                    volatile_acidity: rows[1][16 - 1],
                    glycerol: rows[1][17 - 1],
                    sorbic_acis: rows[1][18 - 1],
                    test_date: rows[1][21 - 1],
                    test_time: rows[1][22 - 1],
                }
            }))

        }
        
    }


    useEffect(() => {
        setLoader(true)
        load();
    }, [])


    if (analysis.length == 0 || parameters.length == 0) return <></>

    const user = jwtDecode(loggedIn);
    const role = user.role;
    if (!user || (role !== 1 && role !== 6 && role !== 7 && role !== 2)) {
        return <><h2>Not allowed</h2></>
    }



    const casasEditable =  (role === 1 || role === 6);
    const globusArrivalEditable =  (role === 1 || role === 7);
    const globusDepartureEditable =  (role === 1 || role === 7);
    const tuesdaywineEditable =  (role === 1 || role === 2);

    let headings = {
        'taste_casas_color': ['Color', 'Amarillo pálido, limpio y brillante.'],
        'taste_casas_aroma': ['Aroma', 'Notas tropicales, suave vainilla, plátano y toques de durazno.'],
        'taste_casas_taste': ['Sabor', 'Fresco y jugoso, crujiente, con notas de chirimoya y cítricos como cáscara de naranja. Vino de cuerpo medio, bien equilibrado entre acidez y dulzura.'],
        'taste_casas_acidity': ['Acidez', 'Media-alta'],

        'taste_globus_arrival_color': ['Farve', 'Klar og lys bleg gul'],
        'taste_globus_arrival_aroma': ['Aroma', 'Tropiske noter, blød vanilje, banan, strejf af fersken.'],
        'taste_globus_arrival_taste': ['Smag', 'Frisk og saftig, sprød, med noter af cherimoya og citrus som appelsinskal. Medium fyldig vin, godt afbalanceret mellem syre og sødme.'],
        'taste_globus_arrival_acidity': ['Syrlighed', 'Medium høj'],

        'taste_globus_departure_color': ['Color', 'Clean and bright pale yellow'],
        'taste_globus_departure_aroma': ['Aroma', 'Tropical notes, soft vanilla, banana, hints of peach.'],
        'taste_globus_departure_taste': ['Taste', 'Fresh and juicy, crispy, hints of chirimoya and citrus like orange peel. Wine with medium body, well balanced between acidity and sweetness.'],
        'taste_globus_departure_acidity': ['Acidity', 'Medium high'],

        'taste_tuesdaywine_color': ['Färg', 'Klar och ljus blekgul'],
        'taste_tuesdaywine_aroma': ['Doft', 'Tropiska toner, mjuk vanilj, banan och inslag av persika.'],
        'taste_tuesdaywine_taste': ['Smak', 'Friskt och saftigt, krispigt, med toner av chirimoya och citrus som apelsinskal. Medelfylligt vin, välbalanserat mellan syra och sötma.'],
        'taste_tuesdaywine_acidity': ['Syra', 'Medelhög'],
    }

    if (analysis.wine_art_nr === '689608' || analysis.wine_art_nr === '6896X8') {
        headings = {
            'taste_casas_color': ['Color', 'Rosa pálido limpio con matices de piel de cebolla.'],
            'taste_casas_aroma': ['Aroma', 'Notas frutales, con toques de durazno y jazmín.'],
            'taste_casas_taste': ['Sabor', 'Vino fresco con notas de grosella, frutos rojos y una sensación mineral al final (salado). Cuerpo ligero a medio, fácil de beber.'],
            'taste_casas_acidity': ['Acidez', 'Media'],
    
            'taste_globus_arrival_color': ['Farve', 'Klar bleg pink til løgskal'],
            'taste_globus_arrival_aroma': ['Aroma', 'Frugtagtige noter, med fersken og strejf af jasmin.'],
            'taste_globus_arrival_taste': ['Smag', 'Frisk vin med solbærnoter, røde frugter og en mineralsk afslutning (let saltet). Let til medium fylde, let at drikke.'],
            'taste_globus_arrival_acidity': ['Syrlighed', 'Medium'],
    
            'taste_globus_departure_color': ['Color', 'Clean pale pink to onion cloth'],
            'taste_globus_departure_aroma': ['Aroma', 'Fruity notes, with peach notes and hints of Jazmin.'],
            'taste_globus_departure_taste': ['Taste', 'Fresh wine with currant notes, red fruits and a mineral sensation at the end (salty). Light to medium body easy to drink.'],
            'taste_globus_departure_acidity': ['Acidity', 'Medium'],
    
            'taste_tuesdaywine_color': ['Färg', 'Klar ljusrosa till lökskal'],
            'taste_tuesdaywine_aroma': ['Doft', 'Fruktiga toner, med inslag av persika och jasmin.'],
            'taste_tuesdaywine_taste': ['Smak', 'Friskt vin med svarta vinbär, röda frukter och en mineralisk känsla i avslutet (saltigt). Lätt till medelfylligt, lättdrucket.'],
            'taste_tuesdaywine_acidity': ['Syra', 'Medel'],
        }
    }

    if (analysis.wine_art_nr === '213008' || analysis.wine_art_nr === '2130X8') {
        headings = {
            'taste_casas_color': ['Color', 'Rojo rubí limpio.'],
            'taste_casas_aroma': ['Aroma', 'Frutal con cerezas y ciruelas, suaves notas de chocolate.'],
            'taste_casas_taste': ['Sabor', 'Vino fresco y jugoso, con un paladar afrutado, con toques de cedro y frutos rojos como cereza. Cuerpo medio-alto, con taninos suaves y agradables. Final con un toque de chocolate negro.'],
            'taste_casas_acidity': ['Acidez', 'Acidez crujiente (media).'],
    
            'taste_globus_arrival_color': ['Farve', 'Klar rubinrød farve'],
            'taste_globus_arrival_aroma': ['Aroma', 'Frugtig med kirsebær og blommer, bløde strejf af chokolade.'],
            'taste_globus_arrival_taste': ['Smag', 'Frisk og saftig vin med frugtrig smag, noter af cedertræ og røde frugter som kirsebær. Medium til høj fylde, med bløde og behagelige tanniner. Afsluttes med lidt mørk chokolade.'],
            'taste_globus_arrival_acidity': ['Syrlighed', 'Sprød syre (medium).'],
    
            'taste_globus_departure_color': ['Color', 'Clean red ruby color.'],
            'taste_globus_departure_aroma': ['Aroma', 'Is fruity with cherries and plums, soft hint of chocolate.'],
            'taste_globus_departure_taste': ['Taste', 'Fresh and juicy wine, with fruity mouth, with hints of cedar and red fruits like cherries. Medium high body, with soft and nice tannins sensation. End with some dark chocolate.'],
            'taste_globus_departure_acidity': ['Acidity', 'Crispy acidity (medium).'],
    
            'taste_tuesdaywine_color': ['Färg', 'Klar rubinröd färg.'],
            'taste_tuesdaywine_aroma': ['Doft', 'Fruktig med körsbär och plommon, mjuka inslag av choklad.'],
            'taste_tuesdaywine_taste': ['Smak', 'Friskt och saftigt vin, med fruktig smak, inslag av cederträ och röda frukter som körsbär. Medelhög fyllighet, med mjuka och behagliga tanniner. Avslutas med en ton av mörk choklad.'],
            'taste_tuesdaywine_acidity': ['Syra', 'Krispig syra (medel).'],
        }
    }

    const TasteNoteColumn = ({type, step}) => {

        let headingKey = `taste_${step}_${type}`;

        const label = headings[headingKey][0];
        const description = headings[headingKey][1];

        return (
            <td className="taste-column">
                {/* <div className="label">{label}:</div> */}
                <label className="check-wrapper">
                    <span>{description}</span>
                    <input
                        type="checkbox"
                        disabled={![`${step}Editable`]}
                        checked={data[`step_${step}`]?.[`taste_note_${type}`] ?? analysis[`step_${step}`]?.[`taste_note_${type}`]}
                        onChange={e => {
                            const target = e.target;
                            setData(d => ({
                                ...d,
                                [`step_${step}`]: {
                                    ...d[`step_${step}`],
                                    [`taste_note_${type}`]: target.checked
                                }
                            }))
                        }}
                    />
                </label>
            </td>
        )
    }

    return (
        <Style className={`layout-container`}>
                <div className="spacer m"></div>

            <div className="analysis card">

                <button className="back-button" onClick={() => history.push(`/analyze/casas`)}>Back</button>

                <h2>{analysis.wine}</h2>

                <div className="spacer m"></div>

                <div className="wrapper">
                    <table cellSpacing={5}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Casas</th>
                                <th>Skanvin Arrival</th>
                                <th>Skanvin Departure</th>
                                <th>Tuesday Wine</th>
                                <th>Variation</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>Order</td>
                                <td><input disabled type="text" value={analysis.casas_order} /></td>
                                <td><input disabled type="text" value={analysis.globus_order ? `${analysis.globus_order}` : '-'} /></td>
                                <td><input disabled type="text" value={analysis.globus_order ? `${analysis.globus_order} (2)` : '-'} /></td>
                                <td><input disabled type="text" value={analysis.tw_order} /></td>
                            </tr>

                            <tr>
                                <td>LOT</td>
                                <td></td>
                                <td></td>
                                <td>
                                    <input 
                                        disabled={!globusDepartureEditable} 
                                        type="text" 
                                        value={data.step_globus_departure?.lot ?? analysis.lot ?? ''} 
                                        onChange={e => {
                                            const target = e.target;
                                            setData(d => ({
                                                ...d,
                                                step_globus_departure: {
                                                    ...d.step_globus_departure,
                                                    lot: target.value
                                                }
                                            }))
                                        }}
                                    />
                                </td>
                                <td></td>
                            </tr>

                            {parameters.sort((a, b) => {
                                if (a.order > b.order) return 1;
                                if (a.order < b.order) return -1;
                                return 0;
                            }).map(m => {


                                const step_casas = data.step_casas?.[m.key] ?? (!isNaN(analysis.step_casas?.[m.key]?.replace(',', '.')) ? Number(analysis.step_casas?.[m.key]?.replace(',', '.')) : undefined);
                                const step_globus_arrival = data.step_globus_arrival?.[m.key] ?? (!isNaN(analysis.step_globus_arrival?.[m.key]?.replace(',', '.')) ? Number(analysis.step_globus_arrival?.[m.key]?.replace(',', '.')) : undefined);
                                const step_globus_departure = data.step_globus_departure?.[m.key] ?? (!isNaN(analysis.step_globus_departure?.[m.key]?.replace(',', '.')) ? Number(analysis.step_globus_departure?.[m.key]?.replace(',', '.')) : undefined);
                                const step_tuesdaywine = data.step_tuesdaywine?.[m.key] ?? (!isNaN(analysis.step_tuesdaywine?.[m.key]?.replace(',', '.')) ? Number(analysis.step_tuesdaywine?.[m.key]?.replace(',', '.')) : undefined);


                                let globus_arrival_variation = Math.abs(step_casas - step_globus_arrival ?? 0);
                                let globus_departure_variation = Math.abs(step_casas - step_globus_departure ?? 0);
                                let tuesdaywine_variation = Math.abs(step_casas - step_tuesdaywine ?? 0);

                                if (m.type === 'percentage') {
                                    globus_arrival_variation = Math.abs(globus_arrival_variation / step_casas) * 100;
                                    globus_departure_variation = Math.abs(globus_departure_variation / step_casas) * 100;
                                    tuesdaywine_variation = Math.abs(tuesdaywine_variation / step_casas) * 100;
                                }

                                let casas_variation_class = '';
                                let globus_arrival_variation_class = m.variation !== 0 ? (globus_arrival_variation > m.variation ? 'warning' : '') : '';
                                let globus_departure_variation_class = m.variation !== 0 ? (globus_departure_variation > m.variation ? 'warning' : '') : '';
                                let tuesdaywine_variation_class = m.variation !== 0 ? (tuesdaywine_variation > m.variation ? 'warning' : '') : '';

                                if (m.type !== 'text') {
                                    // Validate that there's only digits for each input
                                    if (step_casas !== undefined && step_casas !== null && !step_casas.toString().match(/^[0-9.,-]+$/)) {
                                        casas_variation_class = 'warning';
                                    }

                                    if (step_globus_arrival !== undefined && step_globus_arrival !== null && !step_globus_arrival.toString().match(/^[0-9.,-]+$/)) {
                                        globus_arrival_variation_class = 'warning';
                                    }

                                    if (step_globus_departure !== undefined && step_globus_departure !== null && !step_globus_departure.toString().match(/^[0-9.,-]+$/)) {
                                        globus_departure_variation_class = 'warning';
                                    }

                                    if (step_tuesdaywine !== undefined && step_tuesdaywine !== null && !step_tuesdaywine.toString().match(/^[0-9.,-]+$/)) {
                                        tuesdaywine_variation_class = 'warning';
                                    }
                                }


                                // TODO: This is a very, very bad solution. But it works for now.
                                if (m.key === 'f_so2') {

                                    if (step_casas <= 35) casas_variation_class = 'warning';
                                    if (step_globus_arrival <= 30) globus_arrival_variation_class = 'warning';
                                    if (step_globus_departure <= 40) globus_departure_variation_class = 'warning';
                                    if (step_tuesdaywine <= 33) tuesdaywine_variation_class = 'warning';
                                }

                                function setDataParameter(key, value) {

                                    if (value === '') {
                                        setData(d => ({
                                            ...d,
                                            [key]: {
                                                ...d[key],
                                                [m.key]: null
                                            }
                                        }))
                                        return;
                                    }

                                    setData(d => ({
                                        ...d,
                                        [key]: {
                                            ...d[key],
                                            [m.key]: value
                                        }
                                    }))
                                }

                                return (
                                    <tr key={m.key}>
                                        <td>{m.label}</td>

                                        {m.key !== 'aircone' ? <>
                                            <td>
                                                <input 
                                                    type="text"
                                                    pattern="[0-9.]{1,5}"
                                                    placeholder={m.key === 'f_so2' ? `Min: 35` : m.key === 'volume' ? `Liter` : ''}
                                                    className={casas_variation_class + ((m.required || m.key === 'ntu') ? ' required' : '')}
                                                    disabled={!casasEditable}
                                                    value={(!casasEditable && step_casas) ? (Number(step_casas)?.toFixed(m.precision ?? 0)) : (step_casas ?? '')}
                                                    onChange={e => {
                                                        const target = e.target;
                                                        setDataParameter('step_casas', target.value)
                                                    }}
                                                />
                                            </td>
                                            
                                            <td>
                                                <input 
                                                    type="text"
                                                    pattern="[0-9.]{1,5}"
                                                    placeholder={m.key === 'f_so2' ? `Min: 30` : m.key === 'volume' ? `Liter` : ''}
                                                    className={globus_arrival_variation_class + ((m.required || m.key === 'ntu') ? ' required' : '')}
                                                    disabled={!globusArrivalEditable}
                                                    value={(!globusArrivalEditable && step_globus_arrival) ? (Number(step_globus_arrival)?.toFixed(m.precision ?? 0)) : (step_globus_arrival ?? '')}
                                                    onChange={e => {
                                                        const target = e.target;
                                                        setDataParameter('step_globus_arrival', target.value)
                                                    }}
                                                />
                                            </td>
                                        </> : <>
                                            <td>-</td>
                                            <td>-</td>
                                        </>}


                                        <td>
                                            <input 
                                                type="text"
                                                pattern="[0-9.]{1,5}"
                                                placeholder={m.key === 'f_so2' ? `Min: 40` : m.key === 'volume' ? `BIB` : ''}
                                                className={globus_departure_variation_class + (m.required ? ' required' : '')}
                                                disabled={!globusDepartureEditable}
                                                value={(!globusDepartureEditable && step_globus_departure) ? (Number(step_globus_departure)?.toFixed(m.precision ?? 0)) : (step_globus_departure ?? '')}
                                                onChange={e => {
                                                    const target = e.target;
                                                    setDataParameter('step_globus_departure', target.value)
                                                }}
                                            />
                                        </td>


                                        <td>
                                            <input 
                                                onPaste={handleOnPaste}
                                                type="text"
                                                pattern="[0-9.]{1,5}"
                                                placeholder={m.key === 'f_so2' ? `Min: 33` : m.key === 'volume' ? `BIB` : ''}
                                                className={tuesdaywine_variation_class + (m.required ? ' required' : '')}
                                                disabled={!tuesdaywineEditable}
                                                value={(!tuesdaywineEditable && step_tuesdaywine) ? (Number(step_tuesdaywine)?.toFixed(m.precision ?? 0)) : (step_tuesdaywine ?? '')}
                                                onChange={e => {
                                                    const target = e.target;
                                                    setDataParameter('step_tuesdaywine', target.value)
                                                }}
                                            />
                                        </td>

                                        <td>
                                            {m.variation !== 0 ? <>
                                                {m.variation.toFixed(m.precision ?? 2)} {m.type === 'percentage' ? '%' : ''}
                                            </> : <></>}
                                        </td>
                                    </tr>
                                )
                            })}

                            <tr>
                                <td>Note</td>
                                <td>
                                    <textarea
                                        rows={2}
                                        disabled={!casasEditable}
                                        value={data.step_casas?.comment ?? analysis.step_casas?.comment}
                                        onChange={e => {
                                            const target = e.target;
                                            setData(d => ({
                                                ...d,
                                                step_casas: {
                                                    ...d.step_casas,
                                                    comment: target.value
                                                }
                                            }))
                                        }}
                                    ></textarea>
                                </td>

                                <td>
                                    <textarea
                                        rows={2}
                                        disabled={!globusArrivalEditable}
                                        value={data.step_globus_arrival?.comment ?? analysis.step_globus_arrival?.comment}
                                        onChange={e => {
                                            const target = e.target;
                                            setData(d => ({
                                                ...d,
                                                step_globus_arrival: {
                                                    ...d.step_globus_arrival,
                                                    comment: target.value
                                                }
                                            }))
                                        }}
                                    ></textarea>
                                </td>

                                <td>
                                    <textarea
                                        rows={2}
                                        disabled={!globusDepartureEditable}
                                        value={data.step_globus_departure?.comment ?? analysis.step_globus_departure?.comment}
                                        onChange={e => {
                                            const target = e.target;
                                            setData(d => ({
                                                ...d,
                                                step_globus_departure: {
                                                    ...d.step_globus_departure,
                                                    comment: target.value
                                                }
                                            }))
                                        }}
                                    ></textarea>
                                </td>

                                <td>
                                    <textarea
                                        rows={2}
                                        disabled={!tuesdaywineEditable}
                                        value={data.step_tuesdaywine?.comment ?? analysis.step_tuesdaywine?.comment}
                                        onChange={e => {
                                            const target = e.target;
                                            setData(d => ({
                                                ...d,
                                                step_tuesdaywine: {
                                                    ...d.step_tuesdaywine,
                                                    comment: target.value
                                                }
                                            }))
                                        }}
                                    ></textarea>
                                </td>
                            </tr>

                            <tr>
                                <td>Taste notes</td>
                                <td><hr /></td>
                                <td><hr /></td>
                                <td><hr /></td>
                                <td><hr /></td>
                            </tr>

                            <tr>
                                <td>Color</td>
                                <TasteNoteColumn type='color' step='casas' />
                                <TasteNoteColumn type='color' step='globus_arrival' />
                                <TasteNoteColumn type='color' step='globus_departure' />
                                <TasteNoteColumn type='color' step='tuesdaywine' />
                            </tr>

                            <tr>
                                <td>Aroma</td>
                                <TasteNoteColumn type='aroma' step='casas' />
                                <TasteNoteColumn type='aroma' step='globus_arrival' />
                                <TasteNoteColumn type='aroma' step='globus_departure' />
                                <TasteNoteColumn type='aroma' step='tuesdaywine' />
                            </tr>

                            <tr>
                                <td>Taste</td>
                                <TasteNoteColumn type='taste' step='casas' />
                                <TasteNoteColumn type='taste' step='globus_arrival' />
                                <TasteNoteColumn type='taste' step='globus_departure' />
                                <TasteNoteColumn type='taste' step='tuesdaywine' />
                            </tr>

                            {/* <tr>
                                <td>Acidity</td>
                                <TasteNoteColumn type='acidity' step='casas' />
                                <TasteNoteColumn type='acidity' step='globus_arrival' />
                                <TasteNoteColumn type='acidity' step='globus_departure' />
                                <TasteNoteColumn type='acidity' step='tuesdaywine' />
                            </tr> */}



                            <tr>
                                <td></td>
                                <td><hr /></td>
                                <td><hr /></td>
                                <td><hr /></td>
                                <td><hr /></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td><button disabled={!casasEditable} onClick={handleSave('step_casas')}>Save</button></td>
                                <td><button disabled={!globusArrivalEditable} onClick={handleSave('step_globus_arrival')}>Save</button></td>
                                <td><button disabled={!globusDepartureEditable} onClick={handleSave('step_globus_departure')}>Save</button></td>
                                <td><button disabled={!tuesdaywineEditable} onClick={handleSave('step_tuesdaywine')}>Save</button></td>
                            </tr>

                            {role === 1 && <tr>
                                <td></td>
                                <td><button disabled={casasEditable} onClick={handleDelete('step_casas')}>Reset</button></td>
                                <td><button disabled={globusArrivalEditable} onClick={handleDelete('step_globus_arrival')}>Reset</button></td>
                                <td><button disabled={globusDepartureEditable} onClick={handleDelete('step_globus_departure')}>Reset</button></td>
                                <td><button disabled={tuesdaywineEditable} onClick={handleDelete('step_tuesdaywine')}>Reset</button></td>
                            </tr>}



                            <tr>
                                <td>Saved</td>
                                <td><input disabled type="text" value={epochToLocalDateTime(analysis.step_casas?.updatedAt)} /></td>
                                <td><input disabled type="text" value={epochToLocalDateTime(analysis.step_globus_arrival?.updatedAt)} /></td>
                                <td><input disabled type="text" value={epochToLocalDateTime(analysis.step_globus_departure?.updatedAt)} /></td>
                                <td><input disabled type="text" value={epochToLocalDateTime(analysis.step_tuesdaywine?.updatedAt)} /></td>
                            </tr>


                        </tbody>
                    </table>
                </div>

                <div className="spacer m"></div>

            </div>
        </Style>
    )
}

const Style = styled.main`

    width: 1000px;

    hr {
        border: none;
        background-color: #656565;
        height: 1px;
    }

    .analysis {
        grid-column: 1 / 13;

        .back-button {
            display: inline-block;
            width: min-content;
        }
        
        .wrapper {
            overflow: auto;
            padding-bottom: 50px;

            table {

                th {
                    text-align: left;
                }

                tr {
                    td {

                        &:first-of-type {
                            font-size: 12px;
                            /* transform: translateY(15px) rotate(-45deg); */
                            text-align: right;
                        }

                        &.title-row {
                            max-width: 80rem;
                            font-size: 10rem;
                            padding-right: 20rem;
                            font-weight: normal;
                        }

                        input {
                            width: 100%;
                            &.warning {
                                color: red;
                            }

                            &.required {
                                border: white solid 1px;
                            }
                        }

                        textarea {
                            width: 100px;
                            resize: none;
                        }


                        &.taste-column {
                            .label {
                                text-align: center;
                            }

                            .check-wrapper {
                                display: flex;
                                align-items: center;
                                flex-direction: row-reverse;
                                gap: 10px;
                                padding: 10px;

                                input {
                                    width: min-content;
                                    margin-bottom: 0;
                                }

                                span {
                                    font-size: 10px;
                                    width: 100px;
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .mobile & {
        h2 {
            font-size: 20px;
        }

        table {
            tr {
                td {
                    input, textarea {
                        width: 75px !important;
                    }
                }
            }
        }
    }

`