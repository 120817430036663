import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import LoaderContext from '../context/LoaderContext';
import {default as NumberComponent} from '../components/Number';
import TuesdayWine from '../util/TuesdayWine';
import AnalysisRow from '../components/analysis/AnalysisRow';
import Arrow from '../components/Arrow';
import jwtDecode from 'jwt-decode';
import { useDevices } from '../util/Util';
import useSessionState from '../util/useSessionState';

export default function Analyze() {
    
    const {setLoader, loggedIn} = useContext(LoaderContext);

    const [filter, setFilter] = useSessionState('_tw_analysis_filter', 'all')   // all, completed, deleted

    const [mobile] = useDevices();

    const [rawAnalysis, setAnalysis] = useState([])

    async function load(attr = {}) {
        setAnalysis([])
        setAnalysis((await TuesdayWine.get(`/analyze/CP?status=${attr.filter || filter}`)).analysis)
        setLoader(false)
    }

    useEffect(() => {
        setLoader(true)
        load();
    }, [])

    if (!rawAnalysis) return <></>

    const analysis = rawAnalysis.sort((a, b) => {

        const a_eta_stockholm = new Date(a.order.eta_stockholm).getTime();
        const b_eta_stockholm = new Date(b.order.eta_stockholm).getTime();

        if (filter === 'completed') {
            if (a_eta_stockholm < b_eta_stockholm) return 1;
            if (a_eta_stockholm > b_eta_stockholm) return -1;
        }

        if (a_eta_stockholm < b_eta_stockholm) return -1;
        if (a_eta_stockholm > b_eta_stockholm) return 1;

        return 0;
    })

    const user = jwtDecode(loggedIn);
    if (!user || (user.role !== 1 && user.role !== 6 && user.role !== 7)) {
        return <><h2>Not allowed</h2></>
    }

    return (
        <Style className={`layout-container`}>
                <div className="spacer m"></div>

            <div className={`analysis ${mobile ? '' : 'card'}`}>

                {!mobile && <div className="spacer m"></div>}

                <select 
                    value={filter}
                    onChange={e => {
                        setFilter(e.target.value)
                        load({
                            filter: e.target.value
                        });
                    }}
                >
                    <option value={'all'}>Ongoing</option>
                    <option value={'completed'}>Completed</option>
                    {user.role === 1 && <option value={'deleted'}>Deleted</option>}
                </select>

                {!mobile && <div className="spacer m"></div>}

                {!mobile && filter === 'deleted' && (
                    <table>
                        <thead>
                            <tr>
                                <th>Casas</th>
                                <th>Skanvin</th>
                                <th>TW</th>
                                <th>Lot No.</th>
                                <th>Analysis Created</th>
                                <th>Completed steps</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {analysis.map(order => {

                                return order.analysis.filter((analysis) => analysis.deleted == 1).map(analysis => (
                                    <tr key={order.order_id}>
                                        <td>{order.order.cp_order_no}</td>
                                        <td>{order.order.globus_order_no}</td>
                                        <td>{order.order.tuesday_order_no}</td>
                                        <td>{analysis.lot ?? '-'}</td>
                                        <td>{new Date(analysis.created_at).toLocaleDateString('sv-SE', {
                                            year: 'numeric', month: 'numeric', day: 'numeric',
                                        })}</td>
                                        <td>{analysis.completed_names?.length}</td>
                                        <td>
                                            <button onClick={async () => {
                                                await TuesdayWine.put(`/analyze/${analysis.id}/restore`)
                                                load()
                                            }}>Restore</button>
                                        </td>
                                    </tr>
                                ));
                            })}
                        </tbody>
                    </table>
                )}


                {!mobile && filter !== 'deleted' && <table>
                    <thead>
                        <tr>
                            <th>Casas</th>
                            <th>Skanvin</th>
                            <th>TW</th>
                            <th>LOT no.</th>
                            <th>ETA Stockholm</th>
                            <th>Analysis started</th>
                            <th>Filling date</th>
                            <th>Wine</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {analysis.map(order => <AnalysisRow 
                            key={order.id} 
                            order={order} 
                            onDeleted={() => load()}
                            onStatusUpdated={() => load()}
                            canDelete={user.role === 1}
                        />)}
                    </tbody>
                </table>}

                {mobile && filter === 'deleted' && (
                    <div className="not-visible" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10rem',
                        padding: '20rem'
                    }}>
                        <h2>Not visible</h2>
                        <p>This view is not available on mobile</p>
                    </div>
                )}

                {mobile && filter !== 'deleted' && <div className="mobile-rows">
                    {analysis.map(order => <AnalysisRow 
                        key={order.id} 
                        order={order} 
                        onDeleted={() => load()}
                        canDelete={user.role === 1}
                        component={({ order, ongoing, handleShow, handleCreate, canDelete, handleDelete }) => {

                            function handleClick() {
                                if (ongoing) handleShow();
                                else handleCreate();
                            }

                            return (
                                <div className="order">
                                    <div className="head" onClick={handleClick}>
                                        <h3 data-art={order.order.wine}><Arrow right /> {order.order.cp_order_no}</h3>
                                    </div>
                                    
                                    <div className="values">

                                        <Value 
                                            label="Skanvin"
                                            value={order.order.globus_order_no}
                                            unit=''
                                        />

                                        <Value 
                                            label="TW"
                                            value={order.order.tuesday_order_no}
                                            unit=''
                                        />

                                        {ongoing ? (
                                            <Value 
                                                label="Completed"
                                                value={order.analysis[0]?.completed_names?.map(name => {
                                                    return {
                                                        'casas': 'CP',
                                                        'globus_arrival': 'GW1',
                                                        'globus_departure': 'GW2',
                                                        'tuesdaywine': 'TW'
                                                    }[name]
                                                }).join(', ') ?? '-'}
                                                unit=''
                                            />
                                        ) : (
                                            <div></div>
                                        )}
                                        
                                        <Value 
                                            label="eta stockholm"
                                            value={new Date(order.order.eta_stockholm).toLocaleDateString('sv-SE', {
                                                year: 'numeric', month: 'numeric', day: 'numeric',
                                            })}
                                            unit=''
                                        />

                                        <Value 
                                            label="analysis started"
                                            value={ongoing ? new Date(order.analysis[0]?.created_at).toLocaleDateString('sv-SE', {
                                                year: 'numeric', month: 'numeric', day: 'numeric',
                                            }) : '-'}
                                            unit=''
                                        />

                                        {order.analysis[0]?.lot && <Value
                                            label="lot no."
                                            value={order.analysis[0]?.lot}
                                            unit=''
                                        />}
                                        

                                        {ongoing && canDelete && <button onClick={handleDelete}>Delete</button>}

                                    </div>
                                </div>
                            )
                        }}
                    />)}
                </div>}

                <div className="spacer m"></div>

            </div>

            <div className="spacer m"></div>

        </Style>
    )
}


function Value({value, label, unit = 'SEK'}) {
    return <div className="value">
        <label>{label}</label>
        <h2 className="number">
            {isNaN(value) && <>{value}</>}
            {!isNaN(value) && <>{Math.round(value)}<span className="unit"> {unit}</span></>}
            
        </h2>
    </div>
}

const Style = styled.main`


    width: 1500px;

    .analysis {
        grid-column: 1 / 13;

        table {
            th {
                text-align: left;
            }

            tr {
                td {

                    &.title-row {
                        max-width: 80rem;
                        font-size: 10rem;
                        padding-right: 20rem;
                        font-weight: normal;
                    }

                    .status-lights {
                        display: flex;
                        gap: 4px;
                        margin-right: 20px;
                        margin-bottom: 0.5em;

                        div {
                            font-size: 16px;
                            width: 1em;
                            height: 1em;
                            border-radius: 50%;
                            background-color: var(--color-text-negative);

                            &.completed {
                                background-color: var(--color-text-positive);
                            }
                        }
                    }
                }
            }
        }

        .mobile & {
            padding-left: 10rem;
            padding-right: 10rem;
        }
    }

    .mobile-rows {
        display: flex;
        flex-direction: column;
        gap: 10rem;

        .order {
            padding-top: 20rem;
            padding-bottom: 20rem;

            .head {
                h3 {
                    .arrow {
                        margin-right: 10rem;
                        font-size: 20rem;
                    }

                    color: var(--color-orange);
                    display: flex;
                    align-items: center;
                    position: relative;
                    padding-bottom: 1em;

                    &:after {
                        content: attr(data-art);
                        position: absolute;
                        left: 30rem;
                        top: 1.8em;
                        color: white;
                        font-size: 0.7em;
                    }
                }
            }



            .values {

                margin-top: 15rem;
                display: grid;
                gap: 10rem;
                grid-template-columns: 1fr 1fr;

                .value {
                    label {
                        color: var(--color-gray);
                        text-transform: uppercase;
                    }
                }
            }

            &:not(:last-child), &:first-child {
                border-bottom: solid 2px var(--color-gray);
            }
        }
    }


`